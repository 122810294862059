/* Navigation*/
.nav-link>.nav-link {
    padding: 0.3em;
}

.nav-item {
    color: var(--text-color);
}

.dropdown-menu a {
    color: var(--text-color);
}

.dropdown-menu > a:hover, 
.dropdown-menu > .nav-link > a:hover {
    background-color: var(--beige);
    color: var(--text-color);
}

#navbar-nav a,
#navbar-nav a:hover {
    color: var(--text-color);
}

.nav-text {
    font-size: 14px;
    color: var(--text-color);
}

@media only screen and (max-width: 767px) {
    .nav-link>.nav-link {
        padding-left: 1em;
    }
}