/* Keeps gallery contained in modal */
gspro-item-gallery {
    display: block;
}

/* Sets bg color of modal wishlist */
gspro-wishlist[data-mode=expand] {
    background: var(--forest);
}

/** Hide the built-in icon **/
.gspro-c-item-card .gspro-c-item-card__add gspro-icon svg,
.gspro-c-wishlist .gspro-c-wishlist__trigger gspro-icon svg {
    display: none;
}

/** Set the background color of the item card button to white **/
.gspro-c-item-card .gspro-c-item-card__add button {
    background-color: var(--forest);
    border: 2px solid var(--forest);
}

gspro-wishlist .gspro-o-button--primary,
gspro-item-detail .gspro-o-button--primary {
    background-color: var(--forest);
}


.gspro-c-item-card .gspro-c-item-card__add gspro-icon {
    background-image: url("../../public/bag-heart.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

/* Set the custom wishlist icon for the wishlist floating button */
.gspro-c-wishlist .gspro-c-wishlist__trigger gspro-icon {
    background-image: url("../../public/bag-heart.svg");
    background-size: cover;
    background-repeat: no-repeat;
}

gspro-item-gallery .gspro-o-control-bar form {
    border: 1px solid var(--forest);
}

gspro-modal .gspro-o-button--outline {
    border: 1px solid var(--forest);
    color: var(--forest);
}

/* search button */
.gspro-o-search>.gspro-o-button {
    background: var(--forest) !important;
    color: white !important;
}

/* filter button */
/* category button - mobile only */
.gspro-c-item-gallery__toggle-filters,
.gspro-c-item-gallery__toggle-category {
    background: var(--forest) !important;
}

/* card add button color */
gspro-item-card .gspro-o-card__add button {
    background: white !important;
}

gspro-item-gallery .gspro-o-button--outline {
    border: 2px solid var(--forest);
    color: var(--forest);
}

gspro-item-gallery .gspro-o-pagination {
    border-top: 0;
}

/* category text color */
/* category count badge text color */
.gspro-o-category,
.gspro-u-badge {
    color: var(--forest) !important;
}

/* wishlist 'cart' count badge*/
gspro-wishlist .gspro-c-wishlist__count {
    background: var(--forest)!important;
    color: white !important;
}

/* wishlist heart icon color */
gspro-wishlist[data-mode="collapse"] use {
    fill: var(--forest);
}
