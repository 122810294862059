:root {
    --mint: #edf3f4;
    --beige: #fbf5ef;
    --forest: #4d685e;
    --bark: #986a5d;
    --black: #000;
    --white: #fff;
    --text-color: #5c5c5c;
}

/* Text */
a {
    text-decoration: none;
    color: var(--forest);
}

.forest-text {
    color: var(--forest) !important;
}

.catalog-text a {
    text-decoration: underline;
    color: var(--text-color) !important;
}

/* Backgrounds */
.bark-bg {
    background-color: var(--bark);
}

.beige-bg {
    background-color: var(--beige);
}

.mint-bg {
    background-color: var(--mint);
}

/* Button */
.cta {
    background-color: var(--forest) !important;
    color: var(--white)!important;
    margin-bottom: 2rem;
}

/*  Images */
.mw-280 {
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 280px;
    height: 280px;
}

.hero-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    max-height: 420px;
    position: relative;
}

.arched-image {
    border-top-left-radius: 50% 35%;
    border-top-right-radius: 50% 35%;
}

.layover-img {
    max-width: 280px;
}

.mw-380 {
    max-height: 440px;
    max-width: 380px;
}

.limit-height {
    height: 320px;
}

@media only screen and (max-width: 1024px) {
    .hero-image {
        max-width: 80%;
        bottom: -1.8rem;
    }

    .mw-380 {
        max-height: 340px;
        max-width: 280px;
    }

    .layover-img {
        max-width: 200px;
    }
}

@media only screen and (max-width: 767px) {
    .hero-image {
        max-height: 290px;
        bottom: -1.75rem;
        background-position: bottom;
    }

    .mw-280 {
        margin: 2%;
        max-height: 240px;
    }

    .layover-img {
        max-width: 180px;
    }

    .mw-380 {
        max-width: 280px;
    }

    .limit-height {
        height: 290px;
    }
}