/* images */ 
.grid-image {
    background-repeat: no-repeat;
    object-fit: cover;
    width: 100%;
    min-height: 140px;
    height: 170px;
}

.base-img {
    z-index: 0;
    position: relative;
}

.overlay-img {
    z-index: 1;
    position: relative;
    left: 40%;
    bottom: 5rem;
}

.carousel-indicators {
    margin-bottom: 0 !important;
}

/* list items */
.how-to ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    padding: 0;
}

.how-to ol,ul {
    padding-left: 0 !important;
}

.how-to li {
    counter-increment: my-awesome-counter;
    position: relative;
    padding-left: 2.5em;
}

.how-to li::before {
    content: counter(my-awesome-counter);
    position: absolute;
    left: .2em;
    /* Adjust the left position to center the number */
    width: 1.5em;
    /* Adjust the width and height to create a circle */
    height: 1.5em;
    background-color: var(--text-color);
    /* Your background color */
    border-radius: 50%;
    /* Make it a circle */
    text-align: center;
    line-height: 1.5em;
    color: white;
    /* Font */
    font-weight: bold;
    font-family: 'Trirong';
}

.carousel {
    color: var(--text-color);
    font-size: 14px;
}

@media only screen and (max-width: 767px) {
    .base-img {
        bottom: unset;
        z-index: unset;
    }

    .overlay-img {
        left: unset;
        bottom: unset;
        z-index: unset;
    }

    .grid-image {
        object-fit: cover;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 165px;
        height: 140px;
    }
}