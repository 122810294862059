@font-face {
    font-display: 'swap';
    font-family: 'Urbanist';
    src: url('../fonts/Urbanist-Regular.ttf') format('truetype');
}

@font-face {
    font-display: 'swap';
    font-family: 'Trirong';
    src: url('../fonts/Trirong-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: .032px;
    word-spacing: .3125px;
}

body {
    font-family: 'Urbanist', sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: var(--text-color);
}

body strong{
    font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Trirong';
    color: var(--bark);
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 35px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 20px;
}

.text-bark {
    color: var(--bark);
}

@media only screen and (max-width: 767px) {
    h1 {
        font-size: 32px;
    }
    
    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 16px;
    }
}