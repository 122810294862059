/* Custom styles for the accordion */
/* Change the background color */

.accordion-header > button.accordion-button {
    background-color: var(--bark);
    color: white;
}

.accordion-body{
    background-color: var(--beige);
}