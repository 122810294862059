.inventory gspro-c-item-list{
    background: var(--mint);
}

.collections-img{
    object-fit: cover;
    max-height: 340px;
    width: 100%;
}

#inventory > button {
    background-color: transparent;
    border: none;
    color: var(--text-color);
    padding: 0;
    margin: 0;
}

#inventory > button.active {
    font-weight: 600;
}

@media only screen and (max-width: 767px) {
    .collections-img {
        object-fit: cover;
        max-height: 240px;
        width: 100%;
    }
}