input {
    border: 0;
    width: 100%;
}

.questions {
    margin-top: -2.5rem;
}

@media only screen and (max-width: 980px) {

    .contact-overlay-img,
    .contact-base-img {
        min-height: 100px !important;
    }
}

@media only screen and (max-width: 767px) {
    .contact-overlay-img, .contact-base-img {
        min-height: 150px !important;
        padding: .25rem;
    }

    .questions {
        margin: 1rem;
    }
}